import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import LoadMoreButton from "../components/addon/loadMoreButton"

import { DOCTORS_FILTER } from "../service/config"

const DiseasesAndConditions = ({ data: { allNodeTemplatePages: { edges } } }) => {

    const rawData = edges[0]
    const metaTag = [];
    const data = rawData?.node?.relationships?.field_component_type;

    const [bannerData, filterData] = data
    // bannerSection
    const bannerImg = typeof (bannerData?.relationships?.bannerImage?.uri?.url) !== "undefined" ? bannerData?.relationships?.bannerImage?.uri?.url : "/assets/images/patient_care/banner_patient_care.jpg"
    const mobileBannerImg = bannerData?.relationships?.mobileBannerImage?.uri?.url || bannerImg;
    const bannerAlt = typeof (bannerData?.bannerImage?.alt) !== "undefined" && bannerData?.bannerImage?.alt?.length > 0 ? bannerData?.bannerImage?.alt : "Hinduja Hospital"
    const bannerTitle = typeof (bannerData?.title) !== "undefined" && bannerData?.title?.length > 0 ? bannerData?.title : "";
    const bannerSubtitle = typeof (bannerData?.subtitle) !== "undefined" && bannerData?.subtitle?.length > 0 ? bannerData?.subtitle : "";
    // FilterArr
    let mainFilterArr = filterData?.relationships?.field_components

    let [defaultLimit] = useState(6)
    const [selectedLetter, setSelectedLetter] = useState(null)
    const [selectedCard, setSelectedCard] = useState(null)
    const [filterDataOG, setFilterDataOG] = useState(mainFilterArr)
    const [displayData, setDisplayData] = useState([])
   

    const loadMoreData = (data, zero) => {
        let dataArr = data ? data : filterDataOG
        const counter = (zero ? 0 : displayData.length) + defaultLimit
        const arr = [...dataArr.slice(zero ? 0 : displayData.length, counter)]
        if(zero) {
            setDisplayData([...arr])
        } else {
            setDisplayData([...displayData, ...arr])
        }
    }

    const searchByLetter = (letter) => {
        setSelectedLetter(letter)
        let arr = mainFilterArr.filter(el => el?.field_title.charAt(0) === letter)
        setFilterDataOG(arr)
        loadMoreData(arr, true)
    }

    const clearSearch = () => {
        setSelectedLetter(null)
        setFilterDataOG(mainFilterArr)
        loadMoreData(mainFilterArr, true)
    }

    const openModal = (el) => {
        setSelectedCard(el)
    }

    const closeModal = () => {
        setSelectedCard(null)
    }

    const onClick = (event) => {
        if (event?.target?.className.includes('disease_condition_modal_wrap')) {
            closeModal()
        }
    }

    useEffect(() => {
        loadMoreData()
    },[])

    useEffect(() => {
        if(selectedCard?.id) {
            document.body.classList.add("modal-open");
            const modal = document.getElementById(selectedCard?.id);
            if(modal) {
                modal.classList.add("show");
                modal.style.display = 'block';
            }
            
        } else {
            document.body.classList.remove("modal-open");
            const modal = document.getElementsByClassName('disease_condition_modal_wrap')
            if(modal[0]) {
                modal[0].classList.remove("show");
                modal[0].style.display = 'none';
            }
            const bg = document.getElementsByClassName('modal-backdrop')
            if(bg[0]) bg[0].remove()
        }
        setTimeout(() => {
            const bg = document.getElementsByClassName('modal-backdrop')
            if(!bg?.length && selectedCard?.id) window.document.body.insertAdjacentHTML( 'beforeend', `<div class="modal-backdrop show"></div>` );
        }, 50)
        window.addEventListener('click', onClick);
    }, [selectedCard])

    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: [],
                        css: ["/assets/css/common.css", "/assets/css/services.css", "/assets/css/accessibility.css"],
                    }}
                    tags={metaTag}
                />
                <main className="innerpage">
                    <section class="inner_common_banner">
                        <picture className="banner_img">
                            <img src={mobileBannerImg} alt={bannerAlt} className="d-md-none"/>
                            <img src={bannerImg} alt={bannerAlt} className="d-md-block d-none"/>
                        </picture>
                        <div class="banner_content text_left text_dark">
                            <div class="container">
                                <h1>{bannerTitle}</h1>
                            </div>
                        </div>
                    </section>
                    <section class="section-py searchSpeciality-wrap">
                        <div class="container">
                            <div class="alphabets_list">
                                <h2 class="text-center text-uppercase my-4">Search by letter</h2>
                                <ul class="d-flex m-0 p-0">
                                    {
                                        DOCTORS_FILTER.map((el, index) => {
                                            return (
                                                <li key={index}>
                                                    <a href="javascript:void(0)" className={selectedLetter === el ? "active" : ''} onClick={() => searchByLetter(el)} style={{ textDecoration: "none", color: "black" }}>{el}</a>
                                                </li>
                                            )
                                        })
                                    }
                                    {selectedLetter ? <li><a href="javascript:;" onClick={clearSearch} style={{ fontSize: "0.9rem", marginLeft: "15px" }} className="text-primary">clear search</a></li> : null}
                                </ul>
                            </div>
                            <div class="specialty_list_box">
                                <ul class="row">
                                    {displayData?.length 
                                    ? displayData.map((el, index) => {
                                        return (
                                            <li class="col-md-4" key={index}>
                                                <a onClick={() => openModal(el)} href="#" class="ddlink" data-target={'#'+el?.id} data-toggle="modal">
                                                    <span class="link_text">{el?.field_title}</span>
                                                </a>
                                            </li>
                                        )
                                    })
                                    : null}
                                </ul>
                                {!displayData?.length
                                ? <p className="text-center">Currently there is no data.</p> 
                                : null} 
                                {filterDataOG?.length != displayData?.length 
                                ?  <div class="load_more text-center">
                                    <LoadMoreButton
                                        label="Load More"
                                        callbackFunction={() => loadMoreData()}
                                    />
                                </div>
                                : null}
                            </div>
                        </div>
                    </section>
                </main>
                <div class="modal common_modal pay_modal disease_condition_modal_wrap" id={selectedCard?.id} tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
                    <div class="modal-dialog modal-lg modal-dialog-centered">
                        <div class="modal-content">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => closeModal()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="modal-header">
                                <h2>{selectedCard?.field_title}</h2>
                            </div>
                            <div class="modal-body">
                            <div dangerouslySetInnerHTML={{ __html: selectedCard?.text?.processed }} />
                            </div>
                        </div>
                    </div>
                </div> 
            </Layout>
        </>
    )
}

export const query = graphql`
query diseasesAndConditions {

    allNodeTemplatePages(
  
      filter: {path: {alias: {regex: "/diseases-and-condition$/"}}}
  
    ) {
  
      edges {
  
        node {
  
          id
  
          title
  
          path {
  
            alias
  
          }
  
          metatag {
            attributes {
                content
                href
                name
                property
                rel
            }
          }
  
          relationships {
  
            field_component_type {
  
              __typename
  
              ...dacParagraphBanner
  
              ... on paragraph__title_and_description_components {
  
                id
  
                field_name
  
                field_title
  
                field_subtitle
  
                field_link {
  
                  uri
  
                  title
  
                }
  
                relationships {
  
                  field_components {
  
                    ...dacParagraphTitleAndDescription
  
                  }
  
                }
  
              }
  
            }
  
          }
  
        }
  
      }
  
    }
  
  }
  
   
  
  fragment dacParagraphBanner on paragraph__banner {
  
    id
  
    title: field_title
  
    subtitle: field_subtitle
  
    bannerImage: field_banner_image {
  
      alt
  
    }
  
    bannerLink: field_banner_link {
  
      uri
  
      title
  
    }
  
    relationships {
  
      bannerImage: field_banner_image {
  
        id
  
        uri {
  
          value
  
          url
  
        }
  
      }
  
      mobileBannerImage: field_mobile_banner {
  
        id
  
        uri {
  
          url
  
          value
  
        }
  
      }
  
    }
  
    mobileBannerImage: field_mobile_banner {
  
      alt
  
    }
  
  }
  
   
  
  fragment dacParagraphTitleAndDescription on paragraph__title_and_description {
  
    id
  
    field_title
  
    text: field_description {
  
      processed
  
    }
  
    field_cta_link {
  
      title
  
      uri
  
    }
  
    field_text {
  
      processed
  
    }
  
  }
`

export default DiseasesAndConditions